<template>
  <div class="good-list">
    <slot name="header"></slot>
    <search-header ref="search_header" :hide-head="modal" @showAddGoodsModal="showAddGoodsModal" @on-search="handleSearch"
      @on-reset="resetSearch" @on-export="handleExport" :category.sync="categoryList" :brand.sync="brandList"></search-header>
    <div class="table-list" ref="goods_box" v-loading="table.loading">
      <table-list ref="table_list" :current='page.pageNumber' :pageSize='page.pageSize' :status="status"
        :data="table.data" :total="table.total" @on-page-change="changePage" @on-refresh="refreshTable" @on-list="getList"
        @on-sort="handleSort" :modal="modal" @addGood="v => $emit('addGood', v)"></table-list>
    </div>
    <kdx-modal-frame title="添加商品-选择商品类型" v-model="showModal" :footerHide="true" @on-cancel="showModal = false">
      <div class="modal-frame-content">
        <ul>
          <li @click="addGoods('1')" class="isHover">
            <div class="icon iconfont icon-shangpinleixing-shitishangpin-line"></div>
            <div class="name">实体商品</div>
            <div class="desc">物流发货</div>
          </li>
          <li @click="addGoods('2')" class="isHover">
            <div class="icon iconfont icon-shangpinleixing-xuniwupin-line"></div>
            <div class="name">虚拟商品</div>
            <div class="desc">无需物流</div>
          </li>
          <li @click="addGoods('3')" class="isHover">
            <div class="icon iconfont icon-oms-tubiao-shangchuan-"></div>
            <div class="name">组合商品</div>
            <div class="desc">&nbsp;</div>
          </li>
        </ul>
      </div>
    </kdx-modal-frame>
    <slot></slot>
  </div>
</template>

<script>
import SearchHeader from './components/SearchHeader';
import TableList from './components/TableList';
import { Export } from '@/helpers/export';
import { model } from '@/store/goodAddEdit/jsTools/goodModelData.js';
export default {
  name: 'index',
  components: {
    SearchHeader,
    TableList,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      status: '1',
      params: {},
      page: {},
      search: {},
      table: {
        data: [],
        total: 0,
        loading: false
      },
      sort: {},
      showModal: false,
      brandList: [],
      categoryList: []
    };
  },
  watch: {
    '$route.path': {
      handler() {
        let status = {
          inSale: 0, //出售中,上架
          // soldOut: 2, //售罄
          inStock: '1,2', //下架
          inRecycle: 4, //回收站
          // inAll: 6, // 不带条件查询所有的内容
        };

        const { params: { id }, query: { page, size, stype, keywords, isVerify, category } } = this.$route;
        this.status = status[id];
        let params = { status: this.status, pagesize: size * 1 || 10, page: page * 1 || 1, keywords: keywords || '', isVerify: isVerify || '-1', stype: stype || 'single', categoryId: category };


        this.$nextTick(() => {
          this.reset({ ...params });
        });
      },
      immediate: true
    }
  },
  inject: ['returnToTop'],
  created() {
    this.getBrand();
    this.getCategory();
  },
  methods: {
    async getBrand() {
      const result = await this.$api.goods.brandlist();
      this.brandList = result.map(e => {
        return {
          title: e.name,
          value: e.id
        };
      });
    },
    async getCategory() {
      const result = await this.$api.goods.categorylist();
      this.categoryList = result.map(e => {
        return {
          title: e.name,
          value: e.id
        };
      });
    },
    // 添加商品
    showAddGoodsModal() {
      this.showModal = true; // 显示弹窗
    },

    // 添加商品
    addGoods(type) {
      this.$router.push({
        path: '/main/goods/add',
        query: {
          type,
        },
      });
      this.showModal = false; // 隐藏弹窗
    },
    handleSearch(value) {
      this.search = value;
      this.refreshTable();
    },
    handleSort(sort) {
      this.sort = sort;
      this.refreshTable();
    },
    reset(data) {
      this.$nextTick(() => {
        this.$refs['search_header'].reset(data);
        this.$refs['table_list'].reset();
        this.resetSearch(data);
      });
    },
    async getList() {
      this.returnToTop();
      this.table.loading = true;
      console.log(this.search);
      let { stype, keywords, isVerify, categoryId } = this.search;
      const { pageSize: size, pageNumber: page } = this.page;
      let category = categoryId ? categoryId.join(',') : '';



      if (this.modal) {
        isVerify = '1';
        stype = 'single';
      } else {
        const { path, params } = this.$router;
        this.$router.replace({
          path, params,
          query: {
            page, size, stype, keywords, isVerify
          }
        });
      }

      if (stype == 'multi') {
        category = '';
      }

      let result;
      if (this.status == 4) {
        result = await this.$api.goods.recyclelist({ size, page, ptype: stype == 'multi' ? 2 : 1, keyword: keywords, isVerify, categoryId: category });
      } else {
        result = await this.$api.goods.goodslist({ size, page, isRelease: this.status, keyword: keywords, isVerify, categoryId: category }, { stype });
      }
      const { list, count } = result;
      this.table.data = list.map(e => {
        return {
          ...model,
          ...e,
          thumb: e.pic,
          price: e.discou,
          cost_price: e.money,
          type: e.type + '',
          stock: e.inventory,
          real_sales: e.buyNum,
          created_at: this.$dateFormat('YY-mm-dd HH:MM:SS', new Date(e.date * 1000)),
          has_option: '0',
          status: this.status == 4 ? 4 : e.isRelease,
          stype: stype || 'single',
          content: e.content
        };
      });
      this.table.total = count;
      this.table.loading = false;
      // this.table.data = [
      //   {
      //     "id": "11",
      //     "created_at": "2022-08-11 14:49:29",
      //     "title": "米家 小米吸尘器家用无线手持 擦地机拖地机 吸拖一体机 旋转式吸擦一体 防缠绕技术K10 Pro",
      //     "sub_name": "运动卫裤",
      //     "short_name": "",
      //     "type": "0",
      //     "thumb": "image/2022/08/6daa879a0fb87c4181acd168c6799c32.png",
      //     "video": "",
      //     "video_thumb": "",
      //     "unit": "件",
      //     "goods_sku": "",
      //     "bar_code": "",
      //     "sort_by": "8",
      //     "stock": "9996",
      //     "real_sales": "1",
      //     "sales": "1",
      //     "price": "0.00",
      //     "min_price": "0.00",
      //     "max_price": "0.00",
      //     "cost_price": "0.00",
      //     "original_price": "0.00",
      //     "pv_count": "1718",
      //     "has_option": "0",
      //     "is_hot": "0",
      //     "is_recommand": "1",
      //     "is_new": "0",
      //     "status": "1",
      //     "reduction_type": "0",
      //     "ext_field": {
      //       "show_sales": "0",
      //       "show_stock": "1",
      //       "invoice": "0",
      //       "refund": "1",
      //       "return": "1",
      //       "exchange": "1",
      //       "is_delivery_pay": "0",
      //       "auto_putaway": 0,
      //       "single_max_buy": "",
      //       "single_min_buy": "",
      //       "max_buy": "",
      //       "putaway_time": "",
      //       "note": "",
      //       "buy_button_settings": [],
      //       "buy_button_type": "0",
      //       "buy_limit": "0"
      //     },
      //     "dispatch_type": "0",
      //     "is_commission": "0",
      //     "dispatch_express": "1",
      //     "dispatch_intracity": "1",
      //     "weight": "0.00",
      //     "wap_url": "https://demo-free.kaidianxing.com/h5/kdxGoods/detail/index?goods_id=11",
      //     "category": [
      //       {
      //         "goods_id": "11",
      //         "category_id": "1",
      //         "name": "厨卫大电"
      //       },
      //       {
      //         "goods_id": "11",
      //         "category_id": "2",
      //         "name": "生活电器"
      //       },
      //       {
      //         "goods_id": "11",
      //         "category_id": "3",
      //         "name": "手机电脑"
      //       },
      //       {
      //         "goods_id": "11",
      //         "category_id": "4",
      //         "name": "数码产品"
      //       },
      //       {
      //         "goods_id": "11",
      //         "category_id": "5",
      //         "name": "智能设备"
      //       },
      //       {
      //         "goods_id": "11",
      //         "category_id": "6",
      //         "name": "家居装饰"
      //       }
      //     ]
      //   }
      // ];


    },
    resetSearch(data) {
      // this.search = data?.search || {};
      this.search = { ...data };
      let page = {};
      if (data.pagesize) {
        page = {
          pageSize: data.pagesize,
          pageNumber: data.page
        };
      }
      this.refreshTable(page);
    },
    refreshTable(page) {
      this.page = {
        pageSize: 10,
        pageNumber: 1,
        ...page
      };
      this.$refs['table_list'].reset(this.page);
      this.getList();
    },
    changePage(page) {
      this.page = page;
      this.getList();
    },
    handleExport() {
      let params = Object.assign({
        status: this.status,
        export: '1'
      },
        this.search
      );
      Export('manage/goods/index/list', params);
    },
    // 前往续费
    goMoney() {
      this.$router.push({
        path: '/plugins/center/index'
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.good-list {
  background-color: $background-color;
  margin: 20px auto;

  .table-list {
    background-color: #ffffff;
    padding: 0 20px;
    border-radius: 2px;
    position: relative;
  }
}

.modal-frame-content {
  padding-top: 90px;
  height: 380px;
  box-sizing: border-box;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      border: 1px solid #E9EDEF;
      border-radius: 2px;
      width: 150px;
      padding-top: 24px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      .iconfont {
        font-size: 32px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        color: #939799;
      }

      .name {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #636669;
        margin-top: 14px;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
        color: #636669;
        margin-top: 6px;
      }

      &.isHover:hover {
        border: 1px solid $brand-color;

        .iconfont {
          color: $brand-color;
        }

        .name {
          color: $brand-color;
        }
      }

      &.noHover {
        border: 1px solid #E9EDEF;

        .iconfont,
        .name,
        .desc {
          color: #B8B9BD;
        }
      }

      .permModel {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: #F4F6F8;

        p {
          font-size: 12px;
          color: #939799;
          line-height: 20px;
          text-align: center;
          display: flex;
          justify-content: center;

          .iconfont {
            font-size: 12px;
            color: #939799;
            line-height: 20px;
          }

          &:hover {
            color: #2D8CF0;

            .iconfont:hover {
              color: #2D8CF0;
            }
          }
        }
      }
    }
  }
}
</style>