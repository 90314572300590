<template>
	<!--价格、库存-->
	<kdx-modal-frame v-model="value" title="修改商品信息" :width="450" @on-cancel="handleCancel" @on-ok="handleOk"
		:footerHide="model_type == 2">
		<Form ref="form_info" :model="model" :rules="rules" :label-width="120" class="edit-information-modal">
			<FormItem label="卡券：" style="margin-bottom: 0;display: flex" v-if="model_type == 2">
				<template #label>
					<div>
						<span>卡券</span>
						<kdx-hint-tooltip>
							导入卡券数据为新增数据
						</kdx-hint-tooltip>
						<span>：</span>
					</div>
				</template>
				<Upload :action="$baseUrl + 'shopapi/shop/importexcel'" accept=".xls" :max-size="100 * 1024"
					:before-upload="() => showUploadList = true" :show-upload-list="showUploadList"
					:data="{ access_token: $store.state.token, sid: id }" :on-success="handleSuccess"
					:on-exceeded-size="() => this.$Message.error(`文件大小不能超过100MB`)">
					<Button type="primary">+导入卡券Excel表</Button>
				</Upload>
				<a href="https://zzy2.zhangzhengyun.com/down/shop/card_coupon.xls">标准卡券Excel模板下载</a>
			</FormItem>
			<!-- <FormItem label="价格：" prop="price">
                <kdx-rr-input number :fixed="2" :max-value="9999999.99" type="text" v-model="model.price"
                    :disabled="is_activity_goods" placeholder="商品价格" class="width-250">
                    <span slot="append">元</span>
                </kdx-rr-input>
            </FormItem> -->
			<FormItem label="库存：" prop="stock" class="stock">
				<template #label>
					<div>
						<span>库存</span>
						<kdx-hint-tooltip v-if="model_type == 2">
							导入卡券Excel表自动计算
						</kdx-hint-tooltip>
						<span>：</span>
					</div>
				</template>
				<kdx-rr-input type="text" v-model="model.stock" number :fixed="0" :max-value="999999999" placeholder="商品库存"
					class="width-250" :disabled="model_type == 2">
					<span slot="append">件</span>
				</kdx-rr-input>
			</FormItem>
		</Form>
	</kdx-modal-frame>
</template>

<script>
export default {
	name: "EditInformation",
	props: {

		model_type: {
			type: [Number, String]
		},
		price: {
			type: [Number, String]
		},
		stock: {
			type: [Number, String]
		},
		id: {
			type: [Number, String]
		},
		is_activity_goods: {
			type: Boolean
		},
		isExternal: { // 是否是外部使用，当外部使用时需要手动关闭弹窗
			type: Boolean,
			default: false
		},
		stype: {
			type: [String],
			default: 'single'
		},

	},
	data() {
		return {
			value: false,
			model: {
				price: '',
				stock: ''
			},
			rules: {
				price: [{
					required: true,
					message: '价格必填'
				}],
				stock: [{
					required: true,
					message: '库存必填'
				}],
			},
			showUploadList: true,
		};
	},
	methods: {
		setValue() {
			this.value = !this.value;
			if (this.value) {
				this.model = {
					price: this.price,
					stock: this.stock
				};
			}
		},
		handleSuccess(response, file) {
			const { code, msg, data } = response;
			if (code == 0) {
				this.model.stock = data.inventory;
				this.$Message.success(msg);
			} else {
				this.$Message.error(msg);
			}
			this.showUploadList = false;

			this.$emit('on-refresh');
		},
		handleCancel() {
			this.setValue();
		},
		handleOk() {
			// 调用接口
			this.$refs['form_info'].validate(async valid => {
				if (valid) {
					await this.$api.goods.inventoryset({ sid: this.id, ptype: this.stype == 'multi' ? 2 : 1, inventory: this.model.stock }, { toast: 1, loading: true });
					!this.isExternal && this.setValue();
					this.$emit('on-refresh');
				}
			});
		}
	}
};
</script>

<style scoped lang="scss">
.edit-information-modal {
	padding-top: 40px;

	/deep/ .ivu-form-item-content {
		margin-left: 0 !important;
	}
}

.stock {
	/deep/ label {
		display: flex;
		justify-content: flex-end;
		position: relative;
		z-index: 80;
	}
}
</style>
