
<template>
	<div class="good-list-table-list">
		<Table ref="table" :columns="cColumns" :data="getTableData" @on-selection-change="selectChange"
			@on-sort-change="changeSort"></Table>
		<template v-if="!detail">
			<!-- <div class="footer-action" v-if="!modal" v-show="getTableData.length > 0">
			<Checkbox v-model="isSelectAll" @on-change="checkboxChange"></Checkbox>
			<Button v-if="getStatusBtnHandlers.btn" :disabled="selectDisabled"
				@click="handlers({ type: 'multiple', ...getStatusBtnHandlers.data })">{{ getStatusBtnHandlers.btn
				}}</Button>
			<Button :disabled="selectDisabled"
				@click="handlers({ type: 'multiple', content: '确认删除？', api: status == 4 ? 'recycleset' : 'goodsdel' })">删除</Button>
		</div> -->
			<div class="footer-page" v-show="getTableData.length > 0">
				<kdx-page-component :current='current' :pageSize='pageSize' ref="page" :total="total"
					@on-change="changePage"></kdx-page-component>
			</div>
			<!--单规格---价格、库存-->
			<edit-information ref="edit_information" :model_type="information.type" :price="information.price"
				:stock="information.stock" :id="information.id" :is_activity_goods="is_activity_goods && !isFullGoods"
				:stype="information.stype" @on-refresh="handleList"></edit-information>
			<!--排序-->
			<edit-sorting ref="edit_sorting" :model_type="information.type" :id="information.id" :sort_by="information.sort_by"
				:is_activity_goods="is_activity_goods" @on-refresh="handleList"></edit-sorting>
			<!--批量分类-->
			<batch-classify ref="batch_classify" :current-list="selectRows" @on-refresh="handleRefresh"></batch-classify>
			<!--多规格-->
			<multi-specification ref="multi_specification" :id="multiple.id" :model_type="multiple.type"
				:is_activity_goods="is_activity_goods && !isFullGoods" @on-refresh="handleList"></multi-specification>
		</template>
	</div>
</template>

<script>
import EditInformation from "./EditInformation";
import EditSorting from "./EditSorting";
import BatchClassify from "./BatchClassify";
import MultiSpecification from "./MultiSpecification";

export default {
	name: "TableList",
	components: {
		EditInformation,
		EditSorting,
		BatchClassify,
		MultiSpecification,

	},
	props: {
		status: {
			type: [String, Number],
			default: 1
		},
		data: {
			type: Array,
			default: () => []
		},
		// 每页多少条
		pageSize: {
			type: Number,
			default: 10
		},
		// 当前页码
		current: {
			type: Number,
			default: 1
		},
		total: {
			type: Number,
			default: 0
		},
		modal: {
			type: Boolean,
			default: false,
		},
		detail: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			dialogvalue: '',
			columns: [
				// {
				// 	type: 'selection',
				// 	width: 40,
				// 	align: 'center',
				// },
				// {
				// 	title: '排序',
				// 	key: 'sort_by',
				// 	width: 100,
				// 	render: (h, params) => {
				// 		return (
				// 			<div class="inventory">
				// 				<span>{params.row[params.column.key]}</span>
				// 				<span class="iconfont icon-bianji icon"
				// 					onClick={() => {
				// 						this.updateSorting(params.row);
				// 					}}></span>
				// 			</div>
				// 		);
				// 	}
				// },
				{
					title: '商品',
					key: 'shop',
					minWidth: 260,
					render: (h, params) => {
						let label = params.row.category || [];
						const labelComponent = label.map(item => h('span', {}, item));
						let getType;
						switch (params.row.type) {
							case '1':
								getType = {
									goodsName: 'mark real',
									goodsText: '实'
								};
								break;

							case '2':
								getType = {
									goodsName: 'mark virtual',
									goodsText: '虚'
								};
								break;
							default:
								getType = {};
								break;
						}
						return (
							<div class="content-box">
								{/* image */}
								<div class="image">
									<img src={this.$media(params.row.thumb)} alt="" onError={e => { this.replaceImage(e); }} />
								</div>
								{/*商品标题 */}
								<div class="content">
									<div class="content-text"
										style={{ display: this.shopName.id === params.row.id ? 'none' : '-webkit-box' }}
										onClick={e => {
											{/* this.startUpdateShopName(params.row.id);
											e.stopPropagation(); */}
										}}>
										{/*1多规格0单规格*/}
										{/* <span class="mark"
											style={{ display: params.row.has_option === '1' ? 'inline-block' : 'none' }}>多</span> */}
										<span class={getType.goodsName}>
											{getType.goodsText}
										</span>
										<span class="text">{params.row.title}</span>
									</div>
									{/* 商品名称修改*/}
									{/* <Input ref="update_name" id={`update_name${params.row.id}`}
										style={{ display: this.shopName.id === params.row.id ? 'block' : 'none' }}
										value={params.row.title} type='textarea' autofocus={true}
										onOn-change={e => {
											this.changeShopName(e.target.value);
										}}
										onOn-focus={() => {
											this.changeShopName(params.row.title);
										}}
										onOn-blur={() => {
											this.endUpdateShopName(params.row);
										}}></Input> */}
									{/*商品标签 */}
									<p class="label">{labelComponent}</p>
								</div>
							</div>
						);
					}
				},
				{
					title: '品牌',
					key: 'brand',
					minWidth: 100,
					render: (h, params) => {
						let label = params.row.brand || [];
						const labelComponent = label.length > 0 ? label.map(item => h('span', {}, item)) : '-';
						return (
							<div class="label brand">
								{labelComponent}
							</div>
						);
					}
				},
				{
					title: '原价',
					key: 'original',
					minWidth: 100,
					render: (h, params) => {
						return (
							<div class="label">
								<span>￥{params.row.money}</span>
							</div>
						);
					}
				},
				{
					title: '价格',
					key: 'price',
					minWidth: 100,
					render: (h, params) => {
						return (
							<div class="price">
								<span>￥{params.row[params.column.key]}</span>
								{/* <span class="iconfont icon-bianji icon"
									onClick={() => { this.updateInformation(params.row); }}>
								</span> */}
							</div>
						);
					}
				},
				{
					title: '库存',
					key: 'stock',
					minWidth: 90,
					render: (h, params) => {
						let stock = params.row.type == '3' ? '-' : this.formatData(params.row[params.column.key]);
						return (
							<div class="inventory">
								<span>{stock}</span>
								<span class="iconfont icon-bianji icon"
									onClick={() => {
										this.updateInformation(params.row);
									}}></span>
							</div>
						);
					}
				},
				{
					title: '实际销量',
					key: 'real_sales',
					// sortable: 'custom',
					minWidth: 110,
					render: (h, params) => {
						return <div>{params.row.real_sales}</div>;
					}
				},
				{
					title: '创建时间',
					key: 'created_at',
					// sortable: 'custom',
					minWidth: 110,
					render: (h, params) => {
						return <p style='word-break:normal'>{params.row.created_at ? params.row.created_at : '-'}</p>;
					}
				},
				{
					title: '审核状态',
					key: 'isVerify',
					// sortable: 'custom',
					minWidth: 110,
					render: (h, params) => {
						const val = params.row.isVerify == 0 ? { txt: '待审核', color: '#ff9900' } : params.row.isVerify == 1 ? { txt: '审核通过', color: '#19be6b' } : { txt: '审核失败', color: '#FF1F2C' };
						const style = { color: val.color };
						return (
							<p
								style={style} >{val.txt}</p>
						);
					}
				},
				{
					title: '操作',
					key: 'action',
					minWidth: 80,
					render: (h, params) => {
						if (this.modal) {
							return (
								<Button type='text' onClick={() => {
									this.addGood(params.row);
								}}>添加</Button>
							);
						}
						let data = { id: params.row.id, type: 'single', stype: params.row.stype, ...this.getStatusBtnHandlers.data };
						return (
							<div class="action">
								{
									params.row.stype == 'multi' ? <Button type='text' onClick={() => { this.details(params.row); }}>详情</Button> : ''}
								<Button type='text' onClick={() => { this.handlers(data); }}>{this.getStatusBtnHandlers.btn}</Button>
								<Button type='text' onClick={() => { this.handleEdit(params.row, 'edit'); }}>编辑</Button>
								<Button type='text' onClick={() => {
									this.handlers({
										type: 'single',
										id: params.row.id,
										content: '确认删除此商品？',
										api: this.status == 4 ? 'recycleset' : 'goodsdel',
										status: 2,
										stype: params.row.stype
									});
								}}>删除</Button>
							</div>
						);
					}
				}
			],
			selectRows: [], // 选中项
			isSelectAll: false,
			information: {
				price: '',
				stock: '',
				id: '',
				stype: 'single'
			},
			// 多规格
			multiple: {
				id: '',
				type: '0',
			},
			// 商品名称
			shopName: {
				id: -1,
				name: ''
			},
			// 商品是否参加活动
			is_activity_goods: false,
			isFullGoods: false, // 是否是满减商品
			// flag: '0', //获取虚拟卡密权限
		};
	},

	computed: {
		cColumns() {
			if (this.detail) {
				return this.columns.filter(e => e.key != 'action');
			}
			return this.modal ? this.columns.filter(e => e.type != 'selection' && e.key != 'original') : this.columns.filter(e => e.key != 'original');
		},
		getTableData() {
			return this.data;
		},
		getStatusBtnHandlers() {
			let btn = '';
			let data = {};
			if (this.status == 0) {
				btn = '下架';
				data = {
					content: '确认下架?',
					api: 'releaseset',
					status: 1,
				};
			} else if (this.status == '1,2') {
				btn = '上架';
				data = {
					content: '确认上架?',
					api: 'releaseset',
					status: 0,
				};
			} else if (this.status == 4) {
				btn = '恢复';
				data = {
					content: '确认恢复?',
					api: 'recycleset',
					status: 1,
				};
			}
			return { btn, data };
		},
		// 下方按钮是否可操作
		selectDisabled() {
			return this.selectRows.length === 0;
		}
	},

	methods: {
		details(row) {
			this.$router.push({
				path: `/main/goods/list/${row.id}/detail`
			});
		},
		addGood(data) {
			this.$emit('addGood', data);
		},
		digCacel() {
			this.dialogvalue = '';
		},

		// 批量分类
		handleBatch() {
			this.$refs['batch_classify'].setValue();
		},
		// 选中项发生变化
		selectChange(selection) {
			this.selectRows = selection;
			this.isSelectAll = this.selectRows.length === this.data.length;
		},
		// 表格下方全选按钮
		checkboxChange(status) {
			this.data.forEach((item, index) => {
				this.$set(this.data[index], '_checked', status);
			});
			if (status) {
				this.selectRows = this.data;
			} else {
				this.selectRows = [];
			}
		},
		// 商品标题修改
		startUpdateShopName(id) {
			this.shopName.id = id;
			this.$nextTick(() => {
				document.getElementById(`update_name${id}`).getElementsByTagName('textarea')[0].focus();
			});
		},
		changeShopName(val) {
			this.shopName.name = val;
		},
		// 修改商品标题
		endUpdateShopName(row) {
			this.shopName.id = -1;
			// this.$api.goodsApi.editGoodAttribute({
			//     id: row.id,
			//     field: 'title',
			//     value: this.shopName.name
			// })
			//     .then(res => {
			//         if (res.error === 0) {
			//             this.$Message.success('商品名称修改成功');
			//             this.$emit('on-refresh');
			//             this.initFooterData();
			//         }
			//     });
		},
		// 修改价格、库存
		updateInformation(row) {
			this.information.price = row.price;
			this.information.stock = row.stock;
			this.information.id = row.id;
			this.information.type = row.type; //商品类型
			this.information.stype = row.stype;
			this.$nextTick(() => {
				this.$refs['edit_information'].setValue();
			});
		},
		// 修改商品排序
		updateSorting(row) {
			this.is_activity_goods = row?.is_activity_goods == 1 ? true : false;
			this.information.sort_by = row.sort_by;
			this.information.id = row.id;
			this.information.type = row.type; //商品类型
			this.$nextTick(() => {
				this.$refs['edit_sorting'].setValue();
			});
		},
		handlers({
			type,
			id,
			content,
			api,
			status,
			stype
		}) {
			console.log(type, id, content, api, status);
			let ids = [];
			if (type === 'single') {
				ids = [id];
			} else if (type === 'multiple') {
				ids = this.selectRows.map(item => item.id);
			}
			this.$Modal.confirm({
				title: '提示',
				content,
				onOk: async () => {
					await this.$api.goods[api]({ sid: ids.join(','), ptype: stype == 'multi' ? 2 : 1, isRelease: status, operate: status }, { toast: 1, stype });
					this.handleRefresh();
				},
				onCancel: () => { }
			});
		},
		// 编辑
		handleEdit(row, operate) {
			this.$router.push({
				path: '/main/goods/add',
				query: {
					id: row.id,
					// sb: row.sub_shop_id,
					operate,
					status: this.status,
					stype: row.stype
				}
			});
		},
		handleList() {
			// 重新请求数据
			this.$emit('on-list');
			this.initFooterData();
		},
		handleRefresh() {
			// 页面刷新
			this.$emit('on-refresh');
			this.initFooterData();
			this.reset();
		},
		// 重置页码
		reset(page) {
			this.$refs['page'].reset(page);
		},
		changePage(page) {
			this.$emit('on-page-change', page);
			this.initFooterData();
		},
		formatData(data) {
			if (parseInt(data) < 9999) {
				return data;
			} else {
				return `9999+`;
			}
		},
		// 初始化下方的checkbox
		initFooterData() {
			this.isSelectAll = false;
			this.selectRows = [];
		},
		// 排序
		changeSort({ key, order }) {
			if (order === 'normal') {
				this.$emit('on-sort', {});
			} else {
				this.$emit('on-sort', { sort: key, by: order });
			}
		},
	}
};
</script>

<style lang="scss" scoped>
.good-list-table-list {
	/deep/ .ivu-table {
		td {
			.content-box {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;

				.image {
					width: 80px;
					height: 80px;
					margin-right: 10px;
					border: 1px solid $border-color;
					overflow: hidden;
					border-radius: 2px;
					box-sizing: border-box;
					flex-shrink: 0;

					&>img {
						width: 100%;
						height: 100%;
					}
				}

				.content {
					width: 0;
					flex: 1;

					.content-text {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 3;
						overflow: hidden;
						height: 60px;

						.mark {
							@include font-12-16;
							color: #ed4014;
							background-color: #ffefe6;
							border-radius: 2px;
							margin-right: 4px;
							width: 18px;
							height: 18px;
							display: inline-block;
							text-align: center;
							line-height: 18px;

							&:nth-last-child(2) {
								margin-right: 6px;
							}

							&.real {
								background-color: #F0FAFF;
								color: $brand-color;
							}

							&.virtual {
								background-color: #E6FFF9;
								color: #00C5C5;
							}

							&.secret {
								background-color: #FFCDD0;
								color: #FF000F;
							}
						}

						.text {
							@include font-14-20;
						}
					}

					.label {
						// margin-bottom: -10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						>span {
							border-radius: 2px;
							background-color: $background-color;
							@include font-12-16;
							margin-right: 10px;
							color: $text-second;
							display: inline-block;
							height: 20px;
							line-height: 20px;
							padding: 0 8px;
						}
					}
				}
			}

			.brand {
				&.label {
					// margin-bottom: -10px;

					>span {
						border-radius: 2px;
						background-color: $background-color;
						@include font-12-16;
						margin-right: 10px;
						color: $text-second;
						display: inline-block;
						height: 20px;
						line-height: 20px;
						padding: 0 8px;
					}
				}
			}

			// 价格
			.price {
				color: $text-first;
				@include font-14-20-bold;

				.icon {
					display: none;
					cursor: pointer;
					font-size: 16px;
					vertical-align: middle;
					font-weight: normal;
				}

				&:hover {
					>.icon {
						display: inline-block;
					}
				}
			}

			// 库存
			.inventory {
				.icon {
					display: none;
					cursor: pointer;
					font-size: 16px;
					vertical-align: middle;
				}

				&:hover {
					>.icon {
						display: inline-block;
					}
				}
			}

			.market-tag {
				.status-text {
					padding-right: 8px;
					cursor: pointer;
				}
			}

			.action {
				margin-right: -8px;

				.ivu-btn-text {
					margin-right: 8px;
				}
			}
		}
	}

	.footer-page {
		border-top: 1px solid $border-color;
	}
}

// 预览
.table-list-poptip {
	text-align: center;

	>img {
		width: 150px;
		height: 150px;
	}

	.text {
		@include font-12-16;
		color: $text-second;
		padding-top: 10px;
	}

	.ivu-btn-text {
		@include font-12-16;
	}
}

/deep/.gray {
	color: #939799 !important;
}

/deep/ .ivu-table-wrapper {
	overflow: initial;
}
</style>
