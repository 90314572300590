
export default function ergodic(tree, isHandlePath) {
    let cache = [];

    function assembleTree(tree, arr) {
        tree.forEach(item => {
            let tmp = [...arr, item];
            if (item.children && item.children.length) {
                assembleTree(item.children, tmp);
            } else {
                if (!isHandlePath) {
                    cache.push(tmp);
                }
            }
            if (isHandlePath) {
                cache.push(tmp);
            }
        });
    }
    assembleTree(tree, []);
    return cache;
}