
export function formatData(originalVal, originalData) {
    let cache = [];
    function format(data) {
        data.forEach(item => {
            if (new Set(originalVal).has(item.value)) {
                if (item.children && item.children.length) {
                    let _cache = item.children.map(v => v.value)
                    let _flag = _cache.some(v => originalVal.indexOf(v) >= 0)
                    if (!_flag) {
                        cache.push(item.value)
                    }
                    format(item.children)
                } else {
                    // 没有子集直接添加
                    cache.push(item.value)
                }
            } else {
                if (item.children && item.children.length) {
                    format(item.children)
                }
            }
        });
    }
    format(originalData);
    return cache
}