<template>
	<kdx-header-bar>
		<template #header v-if="!hideHead">
			<Button type="primary" @click="showAddGoodsModal">+添加商品</Button>
		</template>
		<Form ref="form" :model="model" :label-width="100" inline @submit.native.prevent>
			<template>
				<FormItem label="商品名称：">
					<Input type="text" v-model="model.keywords" placeholder="商品名称" @on-enter="handleSearch" class="width-340" />
				</FormItem>

				<!-- <FormItem label="创建时间：">
					<DatePicker v-model="model.created_at" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="商品创建时间"
						@on-change="changeDate" class="width-340"></DatePicker>
				</FormItem> -->
				<FormItem label="商品类型：">
					<Select v-model="model.stype" class="width-160" :disabled="hideHead">
						<Option v-for="item in goodsType.label_field" :key="item.field" :value="item.field">{{ item.title }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="商品分类：" v-show="model.stype == 'single'">
					<tree-select class="width-160 tree-select" v-model="model.categoryId" placeholder="全部" :data="category" transfer
						multiple showCheckbox @change-data="changeData" :max-tag-count="2"></tree-select>
				</FormItem>
				<FormItem label="商品品牌：" v-show="model.stype == 'single'">
					<tree-select class="width-160 tree-select" v-model="model.brandId" placeholder="全部" :data="brand" transfer
						multiple showCheckbox @change-data="changeData2" :max-tag-count="2"></tree-select>
				</FormItem>
				<FormItem label="商品状态：">
					<Select v-model="model.isVerify" class="width-160" :disabled="hideHead">
						<Option v-for="item in verify" :key="item.field" :value="item.field">{{ item.title }}
						</Option>
					</Select>
				</FormItem>
			</template>

			<div class="ivu-form-item-btn">
				<Button type="primary" @click="handleSearch">搜索</Button>
				<Button type="text" @click="handleReset">重置</Button>
				<!-- <Button v-if="!hideHead" type="text" @click="handleExport">导出</Button> -->
			</div>
		</Form>
	</kdx-header-bar>
</template>

<script>
import TreeSelect from '@/components/tree-select/index.vue';
export default {
	name: 'SearchHeader',
	components: {
		TreeSelect
	},
	props: {
		hideHead: {
			type: Boolean,
			default: false,
		},
		category: {
			type: Array,
			default: () => []
		},
		brand: {
			type: Array,
			default: () => []
		},
	},


	data() {
		return {
			model: {
				sub_shop_name: '',
				keywords: '',
				label_field: 'single',
				created_at: '',
				sh: '',
				audit_created_at: '',
				stype: 'single',
				audit_status: 'all',
				audit_time: '',
				isVerify: '-1',
				categoryId: [],
				brandId: [],
			},
			// 商品类型
			goodsType: {
				label_field: [
					// {
					// 	field: 'all',
					// 	title: '全部',
					// },
					{
						field: 'single',
						title: '单商品',
					},
					{
						field: 'multi',
						title: '组合商品',
					},
				]
			},
			verify: [
				{
					field: '-1',
					title: '全部',
				},
				{
					field: '0',
					title: '待审核',
				},
				{
					field: '1',
					title: '审核通过',
				},
				{
					field: '2',
					title: '审核失败',
				},
			]
		};
	},
	created() {
		if (this.hideHead) {
			this.model.isVerify = '1';
		}
	},
	methods: {
		changeList(list) {
			function deep(arr = []) {
				if (Array.isArray(arr)) {
					arr.forEach((item) => {
						if (item.children && item.children.length > 0) {
							item.indeterminate = item.children.some(
								(subItem) => {
									return subItem.checked === true;
								}
							);
							deep(item.children);
						}
					});
				}
			}
			deep(list);
		},
		changeData2(list) {
			this.changeList(list);
			this.$emit('update:brand', list);
		},
		changeData(list) {
			// 处理select多选取消选中问题
			this.changeList(list);
			this.$emit('update:category', list);
		},
		// 添加商品
		showAddGoodsModal() {
			this.$emit('showAddGoodsModal');
		},
		// 搜索
		handleSearch() {
			let params = {
				...this.model,
			};
			if (this.model.label_field === 'all') {
				params = {
					...params,
					label_field: '',
				};
			}
			if (this.model.type === 'all') {
				params = {
					...params,
					type: '',
				};
			}

			if (this.model.audit_status === 'all') {
				params = {
					...params,
					audit_status: '',
				};
			}
			this.$emit('on-search', params);
		},
		reset(data) {
			console.log(data);
			data = data || {};
			this.model = {
				keywords: data.keywords || '',
				label_field: !data.label_field ? 'single' : data.label_field,
				created_at: data.created_at || '',
				stype: data.stype ? data.stype : 'single',
				audit_status: (data.audit_status === undefined || data.audit_status === '') ? 'all' : data.audit_status * 1,
				sub_shop_name: data.sub_shop_name || '',
				audit_created_at: data.audit_created_at || '',
				audit_time: data.audit_time || '',
				sh: data.sh || '',
				isVerify: this.hideHead ? '1' : data.isVerify || '-1',
				categoryId: data.categoryId || [],
				brandId: data.brandId || []
			};
			if (this.hideHead) {
				this.model.stype = 'single';
			}
		},
		handleReset() {
			this.$emit('on-search', {});
			this.reset();
		},
		handleExport() {
			this.$emit('on-export');
		},
		changeDate(date) {
			if (date[0]) {
				this.model.created_at = date;
			} else {
				this.model.created_at = '';
			}
		},

		// 日期
		changeDateSh(e) {
			this.model.audit_time = e;
		},
		changeDateaudit_created_at(e) {
			this.model.audit_created_at = e;
		},

	},
};
</script>
<style lang="scss" scoped>
.tree-select {
	/deep/ .ivu-select-selection {
		height: 32px;
		overflow: hidden;
	}
}
</style>
